import React from "react"
import MainContainer from "../containers/MainContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import PageContactSection from "../sections/PageContactSection"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"

export default () => (
  <MainContainer>
    <HeadData 
      title='Contact Us' 
      description='You can contact us if you are interested in receiving more information about the many services offered by Interpacific Asset Management.' 
    />

    <PageHeaderImageBg>
      <HeaderNav />
      <PageHeaderText
        title='Contact Us'
      />
    </PageHeaderImageBg>

    <PageContactSection />

    <HomeAffiliationSection />

    <CTASection />
  </MainContainer>
)
